
import {Component, Prop, Vue} from 'vue-property-decorator';
import ReportService from '@/services/ReportService';
import {resolveError} from '@/utils/notifications';
import IReportRow from '@/types/IReportRow';

@Component({
  name: 'ReportShow',
  components: {
    ReportInfoTable: () => import('@/components/Report/ReportInfoTable.vue'),
  },
})
export default class ReportShow extends Vue {
  @Prop()
  id!: string;

  data: IReportRow[] = [];
  loading: boolean = false;

  created() {
    this.fetchData();
  }

  async fetchData() {
    try {
      this.loading = true;
      this.data = await ReportService.getReport(this.id);
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.loading = false;
    }
  }
}
